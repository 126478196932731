import './App.css';
import Footer from './Footer/Footer';

import HeroSection from './HeroSection/HeroSection';

function App() {
  return (
    <div className="App">

  
   <HeroSection />

   <Footer />
  


    </div>
  );
}

export default App;
